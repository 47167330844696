const podcastData = [
  {
    title: "Maha Samadhi Divas",
    date: {
      day: 3,
      month: 8,
      year: 2024,
    },
    text: `Chinmaya Mahasamadhi Day tribute – with a flagship podcast.
<br/><br/>
Students of Chinmaya Vidyalaya unveiled their inaugural podcast and delved deep into Pujya Gurudev Swami Chinmayananda’s remarkable philosophy for education.
<br/><br/>
A profound discourse ensued with Mr. Anand Sharma, an esteemed educator at the Vidyalaya, and Principal Ms. Archana Soni, who illuminated Gurudev's life and enduring legacy.
<br/><br/>
Mr Sharma delved into the essence of ‘Chinmaya’ and Pujya Gurudev’s visionary intent behind establishing this sacred institution. He further elucidated how young minds can foster a deep connection with the revered spiritual leader.
<br/><br/>
Ms Archana Soni, Principal expounded upon the Chinmaya Vision Program as a transformative tool for personal growth. She also shared a poignant quote from Pujya Gurudev about children and provided invaluable insights into integrating his teachings into our daily lives.
<br/><br/>
Stay tuned for more episodes of the new Chinmaya Vidyalaya podcast!`,
    short_description:
      "Students of Chinmaya Vidyalaya unveiled their inaugural podcast and delved deep into Pujya Gurudev Swami Chinmayananda’s remarkable philosophy for education.",
    video_link:
      "https://chinmayavvdelhi.ac.in/assets/videos/podcasts/08-24/CVND-Maha-Samadhi-Diwas.mp4",
    preview_link:
      "https://chinmayavvdelhi.ac.in/assets/videos/podcasts/08-24/CVND-Maha-Samadhi-Diwas.jpg",
    link: "/podcasts/maha-samadhi-divas-2024",
  },
];

const groupedByMonthYear = podcastData.reduce((acc, item) => {
  const monthYear = `${item.date.year}-${String(item.date.month).padStart(
    2,
    "0"
  )}`;
  if (!acc[monthYear]) {
    acc[monthYear] = [];
  }
  acc[monthYear].push(item);
  return acc;
}, {});

export const podcastDataSortedByMonthYear = Object.keys(groupedByMonthYear)
  .sort()
  .reduce((acc, monthYear) => {
    acc[monthYear] = groupedByMonthYear[monthYear];
    return acc;
  }, {});

export default podcastData;
