import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import "./styles.scss";
import { podcastDataSortedByMonthYear } from "../../data/podcast";
import { parseMonthYear } from "../../utils/utlls";

function formatDateString(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.day;
  const monthName = months[date.month - 1];
  const year = date.year;

  return `${monthName} ${day}, ${year}`;
}

export default function Podcasts() {
  const months = Object.keys(podcastDataSortedByMonthYear).reverse();

  function PodcastsCardsContainer({ monthYear }) {
    const monthData = podcastDataSortedByMonthYear[monthYear].reverse();
    return (
      <div className="podcasts__my">
        <h2>{parseMonthYear(monthYear)}</h2>
        <div className="podcasts__list">
          {monthData.map((item) => (
            <div className="podcasts__list__card" key={item.title}>
              <a href={item.link}>
                <img src={item.preview_link}></img>
                <h3>{item.title}</h3>

                <small>{formatDateString(item.date)}</small>
                <p>{item.short_description}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <Seo
        title="Podcasts | Chinmaya Vidyalaya Vasant Vihar"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
      />

      <div className="podcasts">
        <h1 className="heading">Podcasts</h1>
        <div className="podcasts__container">
          {months.map((item, i) => (
            <PodcastsCardsContainer key={`podcasts-m-${i}`} monthYear={item} />
          ))}
        </div>
      </div>
    </Layout>
  );
}
