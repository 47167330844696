export function toTitleCase(str) {
  if (typeof str !== "string") {
    throw new TypeError("Input must be a string");
  }

  return str.replace(
    /(\*\*|^)(?:\S+\s*)+?(\*\*|$)/g,
    (match, startAsterisks, endAsterisks) => {
      // Remove asterisks for processing
      let content = match.replace(/\*\*/g, "");

      // Apply title case logic
      content = content.replace(/\S+/g, (word) => {
        // Preserve words within parentheses
        if (word.startsWith("(") && word.endsWith(")")) {
          return "(" + word.slice(1, -1) + ")";
        }

        // Capitalize first letter and all letters after hyphens
        return word
          .split("-")
          .map(
            (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
          )
          .join("-");
      });

      // Re-add asterisks if they were present
      return (startAsterisks || "") + content + (endAsterisks || "");
    }
  );
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function parseMonthYear(monthYear) {
  const [year, month] = monthYear.split("-");
  const monthName = months[parseInt(month, 10) - 1];
  return `${monthName}, ${year}`;
}

export function formatDateString(date) {
  const day = date.day;
  const monthName = months[date.month - 1];
  const year = date.year;

  return `${monthName} ${day}, ${year}`;
}
